<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar title="Toto Connect"></f7-navbar>

    <f7-block strong>
      <f7-row>
        <f7-col width="50">
          <f7-button fill raised popup-open="#buy-number"  icon-f7="phone_badge_plus" icon-size="24" >
            Buy Number
          </f7-button>
        </f7-col>
        <f7-col width="50">
          <f7-button fill raised popup-open="#buy-credit" icon-f7="money_pound_circle"  icon-size="24" >Buy Credit</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>


    <f7-block-title>More</f7-block-title>
    <f7-list>
      <f7-list-item link="/faq" title="FAQ"></f7-list-item>
      <f7-list-item link="/terms" title="Terms"></f7-list-item>
    </f7-list>

  </f7-page>
</template>