<template>
  <f7-app v-bind="f7params" >

  <!-- Views/Tabs container -->
  <f7-views tabs class="safe-areas">
    <f7-view id="view-home" main tab tab-active url="/"></f7-view>
  </f7-views>


    <!-- Popup -->
    <f7-popup id="buy-number">
      <f7-view>
        <f7-page>
          <f7-navbar>
            <f7-nav-left>
              <f7-icon f7="phone_badge_plus"></f7-icon>
            </f7-nav-left>
            <f7-nav-title>Buy Number</f7-nav-title>
            <f7-nav-right>
              <f7-link popup-close ><f7-icon f7="multiply_circle"></f7-icon></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
              <f7-list no-hairlines-md>
      <f7-list-input
        label="Name"
        type="text"
        placeholder="Your name"
      ></f7-list-input>

      <f7-list-input
        label="E-mail"
        type="email"
        placeholder="E-mail"
      ></f7-list-input>

      <f7-list-input
        label="URL"
        type="url"
        placeholder="URL"
      ></f7-list-input>

      <f7-list-input
        label="Password"
        type="password"
        placeholder="Password"
      ></f7-list-input>

      <f7-list-input
        label="Phone"
        type="tel"
        placeholder="Phone"
      ></f7-list-input>

      <f7-list-input
        label="Gender"
        type="select"
        >
        <option>Male</option>
        <option>Female</option>
      </f7-list-input>

      <f7-list-input
        label="Birth date"
        type="date"
        placeholder="Birth day"
        defaultValue="2014-04-30"
      ></f7-list-input>

      <f7-list-item
        title="Toggle"
      >
        <template #after>
          <f7-toggle />
        </template>
      </f7-list-item>
    </f7-list>
          </f7-block>
        </f7-page>
      </f7-view>
    </f7-popup>

    <!-- Popup -->
    <f7-popup id="buy-credit">
      <f7-view>
        <f7-page>
          <f7-navbar>
             <f7-nav-left>
              <f7-icon f7="money_pound_circle"></f7-icon>
            </f7-nav-left>
            <f7-nav-title>Buy Credit</f7-nav-title>
            <f7-nav-right>
               <f7-link popup-close ><f7-icon f7="multiply_circle"></f7-icon></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>

          </f7-block>
        </f7-page>
      </f7-view>
    </f7-popup>

    
  </f7-app>
</template>
<script>
  import { ref, onMounted } from 'vue';
  import { f7, f7ready } from 'framework7-vue';


  import routes from '../js/routes.js';
  import store from '../js/store';

  export default {
    setup() {

      // Framework7 Parameters
      const f7params = {
        name: 'totoconnect-more', // App name
        theme: 'auto', // Automatic theme detection
        // App store
        store: store,
        // App routes
        routes: routes,
      };
      onMounted(() => {
        f7ready(() => {


          // Call F7 APIs here
        });
      });

      return {
        f7params
      }
    }
  }
</script>